import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from '@outdoorly/modal';
import { Text } from '@outdoorly/text';
import { HStack, Stack, VStack } from '@chakra-ui/react';
import { Card } from '@outdoorly/card';
import { EntityAvatar } from '@outdoorly/avatar';
import { Button } from '@outdoorly/button';
import { TextInput } from '@outdoorly/input';

import dayjs from 'dayjs';
import { initializeApollo } from '../lib/auth/apollo';

const CODE_ENTRY = gql`
  mutation codeEntry($codeString: String!) {
    codeEntry(codeString: $codeString) {
      codeErrors {
        code
        message
        field
      }
      itemsLinkedToCode {
        qualification {
          id
          name
          logoImage {
            url
          }
        }
        storeCreditPoints
      }
      application {
        id
        status
        qualification {
          id
          name
          logoImage {
            url
          }
        }
        userQualification {
          endTime
        }
      }
      referral {
        id
        status
        referrer {
          id
          publicName
        }
      }
    }
  }
`;

const dispatchStorageEvent = () => window.dispatchEvent(new Event('storage'));

export const enterCode = async (code) => {
  localStorage.removeItem('signUpCodeEntry');
  dispatchStorageEvent();
  const apolloClient = initializeApollo();
  const { data } = await apolloClient.mutate({
    mutation: CODE_ENTRY,
    variables: {
      codeString: code,
    },
    refetchQueries: ['QualificationList'],
  });

  if (data.codeEntry.codeErrors.length) {
    localStorage.setItem('signUpCodeEntry', JSON.stringify({ error: true }));
    dispatchStorageEvent();
    return;
  }
  if (data.codeEntry.application.status === 'APPROVED') {
    localStorage.setItem(
      'signUpCodeEntry',
      JSON.stringify({
        application: data.codeEntry.application,
        credit: data.itemsLinkedToCode?.storeCreditPoints,
      })
    );
    dispatchStorageEvent();
    return;
  }
  //   Not worrying about referral for now
  //   if (data.codeEntry.referral.id) {
  //     localStorage.setItem(
  //       'signUpCodeEntry',
  //       JSON.stringify({
  //         referral: data.codeEntry.referral,
  //         credit: data.itemsLinkedToCode?.storeCreditPoints,
  //       })
  //     );
  //     return;
  //   }
  else if (data.codeEntry.itemsLinkedToCode?.storeCreditPoints) {
    localStorage.setItem(
      'signUpCodeEntry',
      JSON.stringify({
        credit: data.itemsLinkedToCode?.storeCreditPoints,
      })
    );
    dispatchStorageEvent();
    return;
  }
};

const StoreCreditCard = ({ pts }) => (
  <Card
    as={HStack}
    spacing="xl"
    borderColor="scheme.border"
    borderWidth={2}
    rounded="2xl"
    p={6}
    minW="70%"
    justify="center"
  >
    <Text size="lg" fontWeight="500">
      +{pts} store points!
    </Text>
  </Card>
);

StoreCreditCard.propTypes = {
  pts: PropTypes.any,
};

const PostSignInCongratulator = () => {
  const [result, setResult] = useState(null);

  const storageListener = useCallback(() => {
    const result = localStorage?.getItem('signUpCodeEntry');
    if (result) {
      setResult(JSON.parse(result));
    } else {
      setResult(null);
    }
  }, []);

  useEffect(() => {
    storageListener();
    window.addEventListener('storage', storageListener);
    return () => window.removeEventListener('storage', storageListener);
  }, [setResult, storageListener]);

  const onClose = () => {
    localStorage.removeItem('signUpCodeEntry');
    setResult(null);
  };

  const isError = result?.error;
  const [newCode, setNewCode] = useState('');

  const isOpen = !!result?.application || !!result?.credit;

  const application = result?.application;

  const credit = result?.credit;

  const shouldShowNeverExpires =
    application &&
    dayjs(application.userQualification?.endTime).diff(dayjs(), 'year') > 10;

  return isError ? (
    <Modal isOpen onClose={onClose} isCentered>
      <VStack spacing="3xl" mt="3xl">
        <Text size="xl" fontWeight="500">
          Sorry, we couldn&apos;t find that code.{' '}
          <span role="img" aria-label="Detective emoji">
            🕵️‍♀️
          </span>
        </Text>
        <Text size="lg" color="scheme.textSecondary" fontWeight="500">
          Check your code and try again.
        </Text>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            enterCode(newCode);
          }}
        >
          <TextInput
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            placeholder="Your code"
          />
        </form>
        <Button
          onClick={() => enterCode(newCode)}
          colorScheme="red"
          variant="solid"
        >
          Try again
        </Button>
      </VStack>
    </Modal>
  ) : (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      {application ? (
        <VStack spacing="3xl" mt="3xl">
          <Text size="2xl" fontWeight="500">
            Woohoo! You were auto-approved.
          </Text>
          <Text size="lg" align="center" color="scheme.textSecondary">
            We were able to add your affiliation to your profile! Having a
            qualification grants you access to brands at exclusive deals.
          </Text>

          <Card
            as={HStack}
            spacing="xl"
            borderColor="scheme.border"
            borderWidth={2}
            rounded="2xl"
            p={4}
            minW="70%"
          >
            <EntityAvatar src={application.qualification.logoImage?.url} />
            <Stack spacing="lg">
              <Text size="lg" fontWeight="500">
                {application.qualification.name}
              </Text>
              <Text color="scheme.textSecondary">
                {shouldShowNeverExpires ? (
                  'Never expires'
                ) : (
                  <>
                    Expires{' '}
                    {dayjs().to(dayjs(application.userQualification.endTime))}
                  </>
                )}
              </Text>
            </Stack>
          </Card>
          {credit && <StoreCreditCard pts={credit} />}
          <Button
            colorScheme="red"
            size="lg"
            variant="solid"
            w="full"
            onClick={onClose}
          >
            Get shopping!
          </Button>
        </VStack>
      ) : (
        <VStack spacing="3xl" mt="3xl" pt="xl">
          <Text size="2xl" fontWeight="500">
            <span role="img" aria-label="Money face">
              🤑
            </span>{' '}
            Cha-ching! You got yourself some credit.
          </Text>
          <Text size="lg" align="center" color="scheme.textSecondary">
            Use your store credit to get even better deals on great products.{' '}
            <br />
            Or, put it somewhere safe and watch it grow? We&apos;re not
            judging...
          </Text>
          <StoreCreditCard pts={credit} />
          <Button
            colorScheme="red"
            size="lg"
            variant="solid"
            w="full"
            onClick={onClose}
          >
            Get shopping!
          </Button>
        </VStack>
      )}
    </Modal>
  );
};

export default PostSignInCongratulator;
